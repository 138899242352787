
export default function FloatingTitleBlock(props) {
    return (
        <div className={'w-full flex flex-col md:flex-row items-start justify-end ' + (props.className || '')}>

            {(props.titleChildren || props.subTitleChildren || props.titleAdditionChildren || props.image) && <div className='w-full h-full md:w-[40%] flex flex-col md:gap-12 items-start md:items-end justify-between'>

                {(props.titleChildren || props.subTitleChildren || props.titleAdditionChildren) && <div className='w-full flex flex-col md:gap-12 items-start md:items-end'>

                    {props.titleChildren && <Title {...props} />}

                    {props.subTitleChildren && <div className={'max-w-full w-full bg-primary py-4 md:py-6 px-4 md:px-6 z-10 ' + (props.subTitleClassName || '')}>{props.subTitleChildren}</div>}

                </div>}

                {props.image && <div className={'max-w-full w-full aspect-video bg-black z-10 bg-cover bg-center ' + (props.imageClassName || '')} style={{ backgroundImage: 'url(' + props.image + ')' }} />}

            </div>}

            <div className={'w-full md:min-h-full md:w-[60%] self-stretch md:self-auto bg-white ' + (props.mainClassName || '') + ' ' + (props.mainDisablePadding ? '' : 'floating-main-px floating-main-py')}>
                {props.mainChildren && props.mainChildren}
            </div>

        </div>
    );
}

export function Title(props) {
    return (
        <div className={'w-auto max-w-full md:w-full bg-black py-2 px-4 md:px-6 text-white font-condensed font-bold text-3xl sm:text-2xl md:text-3xl lg:text-4xl z-10 ' + (props.titleClassName || '')}>
            {props.titleChildren}
            {props.titleAdditionChildren && <>&nbsp;<span className='block md:inline text-lg text-primary'>{props.titleAdditionChildren}</span></>}
        </div>
    );
}