import { useState } from 'react';
import { Link } from 'react-router-dom';

import FloatingTitleBlock from 'elements/block/FloatingTitleBlock';
import BlockRow from 'elements/block/BlockRow';
import AddressBlock from 'elements/block/generic-blocks/AddressBlock';
import MapBlock from 'elements/block/generic-blocks/MapBlock';

import { NBossCMSPage } from 'cms/NBossCMS.js';

export default function Error404Page(props) {
    const [page, setPage] = useState(null);

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full h-full flex-col'>

            <BlockRow className='bg-secondaryy'>
                <Error404Block />
            </BlockRow>

            {/* <BlockRow className='bg-secondary'>
                <AddressBlock />
            </BlockRow> */}

            {/* <BlockRow className='pt-0 bg-primary'>
                <MapBlock />
            </BlockRow> */}

        </NBossCMSPage>
    );
}

export function Error404Block(props) {
    return (
        <FloatingTitleBlock
            titleChildren={<>_ A keresett oldal nem található</>}
            mainChildren={
                <div className='flex flex-col text-lg'>
                    <div className='font-bold'>404 - Sajnáljuk, de hiba történt.</div>
                    <div>A keresett oldal jelenleg nem elérhető, vagy nem létezik.</div>
                    <div>Kérjük, ellenőrizze a megadott url címet, vagy kezdje a böngészését <Link to='/' className='text-primary font-bold'>főoldalunkról.</Link></div>
                </div>
            }
        />
    );
}