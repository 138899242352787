import { useState } from 'react';

import BlockRow from 'elements/block/BlockRow';
import SearchBlock from 'elements/block/generic-blocks/SearchBlock';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';

export default function TimetablePage(props) {
    const [page, setPage] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const _data = {
        timetable: getValueFromParameterMap(page, 'timetable'),
    }

    const title = _data.timetable && getValueFromParameterMap(_data.timetable, 'title')
    const timetable = _data.timetable && (getValueFromParameterMap(_data.timetable, 'timetable-list') && getValueFromParameterMap(_data.timetable, 'timetable-list').contentList)

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full h-full flex-col'>

            {timetable && <BlockRow className='pt-0'>
                <SearchBlock title='Órarend' subTitle={title} placeholder='Tantárgyak keresése' value={searchTerm} onChange={v => setSearchTerm(v)} />
            </BlockRow>}

            {timetable && <BlockRow className='pt-0'>
                <TimetableList data={timetable} />
            </BlockRow>}

        </NBossCMSPage>
    );
}

function TimetableList(props) {
    return (
        <div className='w-full flex flex-col gap-2'>

            <Line className='hidden md:grid' header />
            {props.data.map((item, index) => (<Line key={index} data={item} />))}

        </div>
    );
}

function Line(props) {
    return (
        <Row
            className={props.className}
            header={props.header}
            dayLabel='Nap'
            day={getValueFromParameterMap(props.data, 'day')}
            timeLabel='Időpont'
            time={getValueFromParameterMap(props.data, 'time')}
            subjectLabel='Tárgynév'
            subject={getValueFromParameterMap(props.data, 'subject')}
            codeLabel='Tárgykód'
            code={getValueFromParameterMap(props.data, 'code')}
            courseLabel='Kurzuskód'
            course={getValueFromParameterMap(props.data, 'course')}
            tutorLabel='Oktató'
            tutor={(getValueFromParameterMap(props.data, 'tutor') && getValueFromParameterMap(getValueFromParameterMap(props.data, 'tutor'), 'teljes-nev'))}
            roomLabel='Terem'
            room={getValueFromParameterMap(props.data, 'room')}
        />
    );
}

function Row(props) {
    return (
        <div className={'p-4 grid grid-cols-2 md:grid-cols-7 gap-4 sm:gap-2 md:gap-4 ' + (props.header ? 'bg-primary-light' : 'bg-white') + ' ' + (props.className || '')}>

            <Cell valueClassName='uppercase' label={props.dayLabel} value={props.day} />
            <Cell label={props.timeLabel} value={props.time} />
            <Cell label={props.subjectLabel} value={props.subject} />
            <Cell label={props.codeLabel} value={props.code} />
            <Cell label={props.courseLabel} value={props.course} />
            <Cell className='order-2 md:order-none col-span-2 md:col-span-1' label={props.tutorLabel} value={props.tutor} />
            <Cell className='order-1 md:order-none' label={props.roomLabel} value={props.room} />

        </div>
    );
}

function Cell(props) {
    const labelClassName = 'text-sm md:text-xs lg:text-sm xl:text-base';

    return (
        <div className={'' + (props.className || '')}>

            {props.label && <div className={'uppercase font-bold ' + labelClassName + ' ' + (props.labelClassName || '') + ' ' + (props.value ? 'md:hidden bg-black text-white px-2 py-1 mb-1' : '')}>{(props.value ? '_ ' : '') + props.label}</div>}
            {props.value && <div className={' ' + labelClassName + ' ' + (props.valueClassName || '')}>{props.value}</div>}

        </div>
    );
}