import { getWebsite } from 'utils/WebsiteUtils';
import { getValueFromParameterMap } from 'cms/NBossCMS';

export const getDateString = (date, options) => {
    var _options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };

    if (options && options.time) {
        _options['hour'] = 'numeric';
        _options['minute'] = 'numeric';
    }

    return date.toLocaleDateString('hu-HU', _options);
};

export const getQueryVariable = (search, variable) => {
    var query = search.substring(1);
    var vars = query.split("&");

    for (let item of vars) {
        var pair = item.split("=");
        if (pair[0] === variable) { return pair[1]; }
    }

    return (false);
};

export const getHighlightRoute = (key) => {
    let route = null;
    const references = getValueFromParameterMap(getWebsite().globalContent, 'highlighted-internal-reference-list') && getValueFromParameterMap(getValueFromParameterMap(getWebsite().globalContent, 'highlighted-internal-reference-list'), 'references').contentList;

    for (let _key in references) {
        if (key === getValueFromParameterMap(references[_key], 'key'))
            route = getValueFromParameterMap(references[_key], 'reference');
    }

    return route;
};

export const chunk = (array, by) => {
    let result = [];
    for (let i = 0; i < array.length; i += by) {
        const chunk = array.slice(i, i + by);
        result.push(chunk);
    }
    return result;
}

export const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;