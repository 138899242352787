
export default function Background(props) {
    return (
        <div className={'absolute top-0 w-full min-h-full flex flex-col items-center overflow-hidden -z-10 ' + (props.className || '')}>

            {/* <div className='w-[1920px] mt-4 min-w-full bg-no-repeat bg-cover bg-top flex-1' style={{ backgroundImage: 'url(/assets/images/bg.png)' }}></div> */}
            <div className='w-[1920px] mt-4 min-w-full bg-no-repeat bg-top flex-1' style={{ backgroundImage: 'url(/assets/images/bg.png)' }}></div>
   
        </div>
    );
}