import { getWebsite } from 'utils/WebsiteUtils';
import { getValueFromParameterMap } from 'cms/NBossCMS';

export default function Footer(props) {
    const _data = {
        title: getValueFromParameterMap(getWebsite().globalContent, 'title'),
        subTitle: getValueFromParameterMap(getWebsite().globalContent, 'sub-title'),
        faculty: getValueFromParameterMap(getWebsite().globalContent, 'faculty'),
        copyright: getValueFromParameterMap(getWebsite().footerContent, 'copyright'),
    }

    return (
        <footer className={'w-full bg-black py-8 flex justify-center site-main-px text-white text-[12px] sm:text-[13px] lg:text-[14px] tracking-wider uppercase ' + (props.className || '')}>

            <div className='w-full max-w-boxed flex flex-col lg:flex-row items-center lg:items-end justify-between gap-6'>

                <div className='flex flex-col md:flex-row gap-6 items-center md:items-end'>

                    <img className='h-[60px] md:mb-1' src='/assets/images/logo-white.svg' alt='Műegyetem 1782' />

                    <div className='flex-1 flex flex-col items-center md:items-start text-center md:text-left'>
                        <div>{_data.title}</div>
                        <div>{_data.subTitle}</div>
                        <div>{_data.faculty}</div>
                    </div>

                </div>

                <div className='flex flex-col xl:flex-row items-center lg:items-end'>
                    <div>{new Date().getFullYear() + ' ' + _data.copyright}</div>
                    <div className='hidden xl:inline-block'>&nbsp;</div>
                    <div>Developed by <a className='text-primary hover:underline underline-offset-2' href='https://creo.hu/' target='_blank' rel='noreferrer'> Creo Group</a>.</div>
                </div>

            </div>

        </footer>
    );
}

