import { useState } from 'react';

import BlockRow from 'elements/block/BlockRow';
import AddressBlock from 'elements/block/generic-blocks/AddressBlock';
import MapBlock from 'elements/block/generic-blocks/MapBlock';
import ContactBlock from 'elements/block/generic-blocks/ContactBlock';

import { NBossCMSPage } from 'cms/NBossCMS';

export default function ContactPage(props) {
    const [page, setPage] = useState(null);

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full h-full flex-col'>

            <BlockRow className='bg-secondary'>
                <AddressBlock />
            </BlockRow>

            <BlockRow className='pt-0 bg-primary'>
                <MapBlock />
            </BlockRow>

            <BlockRow className='bg-secondary'>
                <ContactBlock />
            </BlockRow>

        </NBossCMSPage>
    );
}