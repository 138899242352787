import FloatingTitleBlock from 'elements/block/FloatingTitleBlock';

import { getValueFromParameterMap } from 'cms/NBossCMS';
import { getWebsite } from 'utils/WebsiteUtils';

export default function ContactBlock(props) {
    const _data = {
        phoneNumber: getValueFromParameterMap(getWebsite().globalContent, 'contact-data') && getValueFromParameterMap(getValueFromParameterMap(getWebsite().globalContent, 'contact-data'), 'phone-number'),
        emailAddress: getValueFromParameterMap(getWebsite().globalContent, 'contact-data') && getValueFromParameterMap(getValueFromParameterMap(getWebsite().globalContent, 'contact-data'), 'email-address'),
    }

    return (
        <FloatingTitleBlock
            titleChildren={<>_ Kontakt</>}
            mainChildren={
                <div className='flex flex-col block-content'>
                    {_data.emailAddress && <DataLine title='email cím'><a className='transition-colors hover:text-primary' href={'mailto:' + _data.emailAddress}>{_data.emailAddress}</a></DataLine>}
                    {_data.phoneNumber && <DataLine title='telefonszám'><a className='transition-colors hover:text-primary' href={'tel:' + _data.phoneNumber}>{_data.phoneNumber}</a></DataLine>}
                </div>
            }
        />
    );
}

function DataLine(props) {
    return (
        <div className='grid grid-cols-2 lg:grid-cols-5'>
            <div>{props.title}:</div>
            <div className='font-bold transition-colors'>{props.children}</div>
        </div>
    );
}