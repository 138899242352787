import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getSitemapItemByPath } from 'utils/WebsiteUtils';

export default function Heading(props) {
    const [compact, setCompact] = useState(null);

    let location = useLocation();

    useEffect(() => {
        if (getSitemapItemByPath(location.pathname))
            setCompact(getSitemapItemByPath(location.pathname).pageTemplate !== 'main-page');
        else
            setCompact(true);
    }, [location]);

    return (
        <div className={'w-full flex justify-center site-main-px ' + (compact ? 'pb-12 ' : 'pb-12 sm:pb-16 ') + (props.className || '')}>

            <div className='relative w-full max-w-boxed flex flex-col md:flex-row gap-6 md:gap-0 items-end md:items-start justify-between'>

                <Motto compact={compact} />

                <Center className={'opacity-0  ' + (compact ? '' : 'lg:opacity-100')} />

                <Quot />

            </div>

        </div>
    );
}

function Motto(props) {
    const lineVerticalPadding = props.compact ? 'pb-1.5 pt-3 ' : 'pb-1.5 pt-3 ';
    const lineHorizontalPadding = props.compact ? 'px-4 ' : 'px-4 ';
    return (
        <div className={'relative flex flex-col items-end text-white font-condensed uppercase font-bold tracking-wide ' + (props.compact ? 'text-[56px] sm:text-[86px] md:text-[64px] lg:text-[48px] gap-2 ' : 'text-[56px] sm:text-[86px] md:text-[64px] xl:text-[86px] 2xl:text-[118px] gap-2 2xl:gap-4')}>

            <div className='flex gap-2'>
                <div className={'bg-transparent leading-none z-10 ' + lineVerticalPadding}>_</div>
                <div className={'bg-black leading-none z-10 ' + lineVerticalPadding + lineHorizontalPadding}>Üzleti jog</div>
            </div>

            <div className={'bg-black pb-1.5 pt-3 leading-none z-10 ' + lineVerticalPadding + lineHorizontalPadding}>tanszék</div>

            <div className={'absolute top-0 left-0 w-full h-full flex items-center justify-start md:justify-end pointer-events-none ' + (props.compact ? 'opacity-100 md:opacity-100' : 'opacity-100 md:opacity-100 lg:opacity-0')}>
                <div className='text-[210px] -mt-[60px] sm:mt-[10px] md:-mt-[60px] -mr-[45px] ml-0 sm:ml-[20px] md:ml-[0px] text-white opacity-30 md:opacity-50 font-condensed font-medium'>§</div>
            </div>

        </div>
    );
}

function Quot(props) {
    const quotStyle = 'absolute w-[52px] md:w-[64px] xl:w-[80px] xl:w-[95px] 2xl:w-[110px] aspect-square opacity-30'
    return (
        <div className='flex flex-col sm:flex-row items-end leading-none font-condensed'>

            <div className='relative'>
                <img className={'-bottom-1 md:-bottom-5 -left-3 md:-left-8 pointer-events-none ' + quotStyle} src='/assets/images/quot.svg' alt='' />
                <img className={'-bottom-0 md:-bottom-2 -right-3 md:-right-8 rotate-180 pointer-events-none ' + quotStyle} src='/assets/images/quot.svg' alt='' />
                <div className='text-white font-bold text-[34px] sm:text-[34px] md:text-[24px] lg:text-[32px] xl:text-[50px] 2xl:text-[60px]'>A technika semmi<br /><span className='ml-16 xl:ml-24'>humánum nélkül</span></div>
            </div>

            <div className='pt-[10px] sm:pt-[20px]'>
                <div className='font-light text-[20px] xl:text-[24px] 2xl:text-[32px] -mb-[20px] -ml-[6px] italic'>Gábor Dénes</div>
            </div>

        </div>
    );
}

function Center(props) {
    return (
        <div className={'absolute top-0 left-0 w-full h-full flex items-start justify-center pointer-events-none ' + (props.className || '')}>
            <div className='text-[210px] -mt-[90px] text-white opacity-50 font-condensed font-medium drop-shadow-[0_10px_10px_rgba(255,255,255,0.75)]'>§</div>
        </div>
    );
}