let website = null;

export const setWebsite = (data) => {
    website = data;
};

export const getWebsite = () => {
    return website;
};

export const getSitemapItemByPath = (path) => {
    if (website && website.sitemap && website.sitemap[path])
        return website.sitemap[path]
    return null;
};