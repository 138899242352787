import { useState } from 'react';
import { MdAlternateEmail, MdPhone, MdMeetingRoom } from 'react-icons/md';
import { CgChevronDown } from 'react-icons/cg';

import { Title } from 'elements/block/FloatingTitleBlock';
import BlockRow from 'elements/block/BlockRow';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import { chunk } from 'utils/GenericUtils';

export default function ColleaguesPage(props) {
    const [page, setPage] = useState(null);

    const _data = {
        colleagueList: getValueFromParameterMap(page, 'collegues-list') && getValueFromParameterMap(page, 'collegues-list').contentList,
    }

    const chunkColleagueList = (by) => {
        let list = [];
        for (let i = 0; i < _data.colleagueList.length; i++)
            if (!getValueFromParameterMap(_data.colleagueList[i], 'kiemelt'))
                list.push(_data.colleagueList[i]);
        return chunk(list, by);
    };

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='relative w-full h-full flex-col'>

            <div className='absolute top-0 left-0 w-full h-full overflow-hidden'>
                <div className='relative w-full h-full'>
                    <div className='absolute w-[420px] md:w-[675px] aspect-square bg-primary rounded-full top-[750px] -left-[180px] md:-left-[225px] ' />
                    <div className='absolute w-[880px] md:w-[1080px] aspect-square bg-primary rounded-full top-[1280px] -right-[720px]' />
                    <div className='absolute w-[480px] aspect-square bg-primary rounded-full top-[2280px] -left-[300px]' />
                    <div className='absolute w-[480px] md:w-[675px] aspect-square bg-primary rounded-full top-[2900px] -right-[200px]' />
                </div>
            </div>

            {_data.colleagueList && <BlockRow className='bg-secondary pt-0 sm:pb-10 md:pb-32 overflow-hidden md:overflow-visible' fullWidth>

                <div className='relative w-full flex flex-col collegues-gap items-center justify-center'>

                    <div className='relative w-full max-w-[940px] flex flex-col md:flex-row items-start'>

                        <Title titleChildren={'_ Munkatársaink'} titleClassName='sm:text-5xl' />

                        <div className='w-full flex flex-col items-center collegues-gap z-10 sm:mt-10 md:mt-0'>
                            {_data.colleagueList.map((item) => (getValueFromParameterMap(item, 'kiemelt') && <Colleague key={item.key} data={item} />))}
                        </div>

                        <div className='absolute h-[580px] lg:h-[660px] aspect-square bg-primary rounded-full top-[30px] md:-top-[30px] -right-[300px] md:right-auto lg:-left-[30px]' />

                    </div>

                    <div className='w-full flex flex-col collegues-gap sm:site-main-px md:pl-16'>
                        {chunkColleagueList(2).map((item, index) => (<Row className='' key={index} list={item} />))}
                    </div>

                </div>

            </BlockRow>}

        </NBossCMSPage>
    );
}

function Row(props) {
    return (
        <div className={'w-full flex flex-col xl:flex-row items-center odd:pr-0 even:pl-0 odd:xl:pr-16 odd:2xl:pr-64 even:xl:pl-16 even:2xl:pl-64 odd:justify-start even:justify-end collegues-gap ' + (props.className || '')}>
            {props.list.map((item) => (<Colleague className='even:md:ml-[180px] even:lg:ml-[360px] even:xl:ml-0 odd:md:mr-[180px] odd:lg:mr-[360px] odd:xl:mr-0' key={item.key} data={item} />))}
        </div>
    );
}

function Colleague(props) {
    const [open, setOpen] = useState(false);

    const _data = {
        order: getValueFromParameterMap(props.data, 'order'),
        name: getValueFromParameterMap(props.data, 'teljes-nev'),
        position: getValueFromParameterMap(props.data, 'beosztas'),
        room: getValueFromParameterMap(props.data, 'szoba'),
        email: getValueFromParameterMap(props.data, 'e-mail-cim'),
        phone: getValueFromParameterMap(props.data, 'telefonszam'),
        content: getValueFromParameterMap(props.data, 'bemutatkozo'),
        highlight: getValueFromParameterMap(props.data, 'kiemelt'),
        image: getValueFromParameterMap(props.data, 'foto') && getValueFromParameterMap(props.data, 'foto').downloadUrl,
    }

    return (
        <div className={'relative bg-white w-full md:w-[460px] 2xl:w-[500px] md:pl-[155px] lg:pl-[155px] p-[15px] sm:p-[25px] lg:p-[35px] block sm:flex md:block flex-row items-start gap-[20px] ' + (props.className || '')} style={{ order: _data.order }}>

            <div className='flex flex-row items-center gap-[20px]'>

                <div className='shadow-xl md:absolute order-2 rounded-full w-[110px] sm:w-[205px] md:w-[160px] lg:w-[180px] aspect-square -top-6 -left-6 lg:-top-8 lg:-left-12 flex items-center justify-center overflow-hidden'>
                    <div className='w-full h-full bg-cover bg-center bg-no-repeat bg-primary-light' style={{ backgroundImage: 'url(' + _data.image + ')' }} />
                </div>

                <div className='sm:hidden flex flex-col gap-1 flex-1 order-1 text-right font-condensed'>
                    {_data.name && <div className='uppercase font-extrabold'>{_data.name}</div>}
                    {_data.position && <div className='text-sm'>{_data.position}</div>}
                </div>

            </div>

            <div className={'flex-1 flex flex-col gap-4 ' + ((_data.email || _data.phone || _data.room || _data.content) ? 'mt-4 sm:mt-0' : '')}>

                <div className='hidden sm:flex flex-col gap-1'>
                    {_data.name && <div className='uppercase text-lg font-extrabold tracking-widest'>{_data.name}</div>}
                    {_data.position && <div className='text-sm font-bold'>{_data.position}</div>}
                </div>

                {(_data.email || _data.phone || _data.room) && <div className='flex flex-col gap-1'>
                    {_data.email && <ContactLine valueClassName='md:text-[13px] 2xl:text-base' icon={<MdMeetingRoom />} value={_data.room} />}
                    {_data.email && <ContactLine icon={<MdAlternateEmail />} value={_data.email} href={'mailto:' + _data.email} />}
                    {_data.phone && <ContactLine icon={<MdPhone />} value={_data.phone} href={'tel:' + _data.phone} />}
                </div>}

                {_data.content && <Introduction {...props} open={open} onChangeOpen={(o) => setOpen(o)} />}

            </div>

        </div>
    );
}

function ContactLine(props) {
    return (
        <a className={'flex gap-1.5 items-center text-sm sm:text-base md:text-base justify-end sm:justify-start ' + (props.href ? 'text-primary group' : '')} href={props.href}>
            <div className='mt-[1px] order-2 sm:order-1'>{props.icon}</div>
            <div className={'group-hover:underline order-1 sm:order-2 ' + (props.valueClassName || '')}>{props.value}</div>
        </a>
    );
}

function Introduction(props) {
    const _data = {
        content: getValueFromParameterMap(props.data, 'bemutatkozo'),
    }

    return (
        <div className='flex flex-col'>

            <button className='flex items-center justify-end sm:justify-between gap-1 bg-black  hover:bg-primary px-1 sm:px-2 sm:pl-4 py-1 transition-colors group' onClick={() => props.onChangeOpen(!props.open)}>
                <div className='text-white group-hover:text-black font-bold transition-colors uppercase'>{props.open ? '_ Bezárás' : '_ Bemutatkozás'}</div>
                <CgChevronDown className={'text-primary group-hover:text-white text-4xl transition ' + (props.open ? 'rotate-180' : '')} />
            </button>

            {props.open && <div className='p-4 bg-secondary p-margin text-xs md:text-[13px] flex flex-col gap-2'>
                <div dangerouslySetInnerHTML={{ __html: _data.content }} />
            </div>}

        </div>
    );
}