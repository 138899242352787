import { useState } from 'react';

import BlockRow from 'elements/block/BlockRow';
import EventBlock from 'elements/block/generic-blocks/EventBlock';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';

export default function ResearchPage(props) {
    const [page, setPage] = useState(null);

    const _data = {
        eventList: getValueFromParameterMap(page, 'event-list') && getValueFromParameterMap(page, 'event-list').contentList,
    }
    
    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full h-full flex-col'>

            {_data.eventList && _data.eventList.map((item, index) => (
                <BlockRow className={index ? 'pt-0' : ''} key={item.key}>
                    <EventBlock data={item} />
                </BlockRow>
            ))}

        </NBossCMSPage>
    );
}