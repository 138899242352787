
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Spin as Hamburger } from 'hamburger-react'

import MainMenu from 'components/MainMenu';
import MenuSlider from 'components/MenuSlider';

import { getWebsite } from 'utils/WebsiteUtils';
import { getValueFromParameterMap } from 'cms/NBossCMS';

import CmsFrontendControl from 'services/CmsFrontendControl';

export default function Header(props) {
    const [scroll, setScroll] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const onScroll = () => { setScroll(window.pageYOffset); };
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <>
            <header className={'w-full sticky top-0 h-16 flex justify-center ' + (props.className || '')}>

                <MainHeader className={'absolute top-0 lg:transition-all hidden lg:flex will-change ' + (scroll ? 'top-[100%] scale-95 opacity-0 pointer-events-none' : 'scale-100 top-0 opacity-100 pointer-events-auto')} />
                <StickyHeader className={'absolute top-0 lg:transition-all will-change ' + (scroll ? 'top-0' : ' top-0 lg:-top-[100%]')} menuOpen={menuOpen} toggleMenu={() => setMenuOpen(!menuOpen)} />

            </header>

            <div className='fixed top-0 flex lg:hidden z-40'>
                <MenuSlider open={menuOpen} onSliderClose={() => setMenuOpen(false)} />
            </div>

        </>
    );
}

function MainHeader(props) {
    const _data = {
        title: getValueFromParameterMap(getWebsite().globalContent, 'title'),
        subTitle: getValueFromParameterMap(getWebsite().globalContent, 'sub-title'),
        phoneNumber: getValueFromParameterMap(getWebsite().globalContent, 'contact-data') && getValueFromParameterMap(getValueFromParameterMap(getWebsite().globalContent, 'contact-data'), 'phone-number'),
        emailAddress: getValueFromParameterMap(getWebsite().globalContent, 'contact-data') && getValueFromParameterMap(getValueFromParameterMap(getWebsite().globalContent, 'contact-data'), 'email-address'),
    }

    return (
        <div className={'w-full pt-4 pb-3 flex justify-center site-main-px text-sm xl:text-base font-condensed xl:font-sans 2xl:tracking-wider ' + (props.className || '')}>

            <div className='w-full max-w-boxed flex flex-col gap-8 transition-none'>

                <div className='flex gap-6'>

                    <div className='flex-1 uppercase font-medium leading-5'>
                        <div>{_data.title}</div>
                        <div>{_data.subTitle}</div>
                    </div>

                    <Link to='/'>
                        <img className={'h-[72px] xl:h-[84px]'} src='/assets/images/logo-black.svg' alt='Műegyetem 1782' />
                    </Link>

                    <div className='flex-1 flex items-start justify-end flex-row gap-6 xl:gap-8 leading-5'>

                        {_data.phoneNumber && <DataLine title='telefon'><a className='transition-colors hover:text-white' href={'tel:' + _data.phoneNumber}>{_data.phoneNumber}</a></DataLine>}
                        {_data.emailAddress && <DataLine title='email'><a className='transition-colors hover:text-white' href={'mailto:' + _data.emailAddress}>{_data.emailAddress}</a></DataLine>}
                        {/* <DataLine title='nyelv'><LanguageChanger /></DataLine> */}

                    </div>

                </div>

                <MainMenu itemClassName='text-black hover:text-white text-lg' />

            </div>

        </div>
    );
}

function StickyHeader(props) {
    return (
        <div className={'bg-black h-16 w-full flex justify-center site-main-px ' + (props.className || '')}>

            <div className='w-full max-w-boxed flex gap-6 items-center justify-between transition-none'>

                <Link to='/'>
                    <img className='h-[40px]' src='/assets/images/logo-white.svg' alt='Műegyetem 1782' />
                </Link>

                <div>

                    <MainMenu itemClassName='hidden md:block text-white hover:text-primary text-[15px] lg:text-lg' />

                    <div className='block md:hidden'>
                        <Hamburger size={25} color="#ffffff" direction="left" toggled={props.menuOpen} toggle={() => props.toggleMenu()} />
                    </div>

                </div>

            </div>

        </div>
    );
}

function DataLine(props) {
    return (
        <div className='flex flex-col'>
            <div>{props.title}:</div>
            <div className='font-bold transition-colors'>{props.children}</div>
        </div>
    );
}

function LanguageChanger(props) {
    const _data = {
        availableLocales: getWebsite().availableLocales,
    };

    const onLangChange = (key) => {
        CmsFrontendControl.setLocale(key).then(result => { result && window.location.reload() });
    };

    return (
        <div className='flex gap-2'>
            {_data.availableLocales.map((item) => (<div key={item.key} className='lowercase hover:text-white cursor-pointer' onClick={()=>onLangChange(item.key)}>{item.name}</div>))}
        </div>
    );
}