import { Link, NavLink } from 'react-router-dom';

import { getWebsite } from 'utils/WebsiteUtils';
import { getValueFromParameterMap } from 'cms/NBossCMS';

export default function MainMenu(props) {
    let _data = {
        menu: getValueFromParameterMap(getWebsite().globalContent, 'main-menu'),
    };

    return (
        <div className={'flex gap-6 xl:gap-10 items-center justify-center ' + (props.className || '')}>

            {_data.menu && _data.menu.itemList.map((item) => (<Item key={item.key} data={item} itemClassName={props.itemClassName} />))}

        </div>
    );
}

function Item(props) {
    const activeBaseClassName = 'h-0.5 transition-all';
    const normalClassName = 'w-[0%]';
    const activeClassName = 'bg-white w-[50%]';

    return (
        <Link to={props.data.fullPath} className={'font-condensed font-semibold uppercase hover:text-white transition-colors flex flex-col select-none ' + (props.itemClassName || '')} >
            {/* <NavLink to={props.data.fullPath} className={({ isActive }) => isActive ? (activeBaseClassName + ' ' + activeClassName) : (activeBaseClassName + ' ' + normalClassName)}></NavLink> */}
            {props.data.title}
        </Link>
    );
}