import { useState } from 'react';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import BlockRow from 'elements/block/BlockRow';


export default function ContentPage(props) {
    const [page, setPage] = useState(null);

    const _data = {
        title: getValueFromParameterMap(page, 'title'),
        subTitle: getValueFromParameterMap(page, 'sub-title'),
        lead: getValueFromParameterMap(page, 'lead'),
        content: getValueFromParameterMap(page, 'content'),
    }

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full h-full flex-col flex-1'>

            <BlockRow className='bg-secondary' fullWidth>

                <div className='w-full flex flex-col gap-8 items-start'>

                    <div className='flex flex-col items-start'>
                        <div className='font-condensed uppercase font-bold text-5xl 2xl:text-6xl text-white inline bg-black pt-1 pb-2 px-3' style={{ boxDecorationBreak: 'clone' }}>{_data.title}</div>
                        {_data.subTitle && <div className='bg-primary font-condensed font-bold text-xl 2xl:text-2xl uppercase px-3 pt-1.5 pb-2'>{_data.subTitle}</div>}
                    </div>

                    {_data.lead && <div className='paragraph font-bold' dangerouslySetInnerHTML={{ __html: _data.lead }} />}

                    {_data.content && <div className='paragraph' dangerouslySetInnerHTML={{ __html: _data.content }} />}

                </div>

            </BlockRow>

        </NBossCMSPage>
    );
}