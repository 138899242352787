import { Link } from 'react-router-dom';
import { CgChevronDown } from 'react-icons/cg';

import FloatingTitleBlock from 'elements/block/FloatingTitleBlock';

import { getValueFromParameterMap } from 'cms/NBossCMS';
import { getWebsite } from 'utils/WebsiteUtils';

export default function ColleaguesButtonBlock(props) {
    const _data = {
        collegues: getValueFromParameterMap(getWebsite().globalContent, 'highlighted-pages') && getValueFromParameterMap(getValueFromParameterMap(getWebsite().globalContent, 'highlighted-pages'), 'collegues'),
    }

    return (
        <FloatingTitleBlock
            mainDisablePadding
            mainClassName={'group transition-colors floating-main-px py-1 ' + (props.dark ? 'hover:bg-black ' : 'hover:bg-primary ')}
            mainChildren={
                <Link to={_data.collegues} className='flex items-center justify-between'>
                    <div className={'text-xl md:text-3xl lg:text-4xl font-bold uppercase transition-colors ' + (props.dark ? 'group-hover:text-white' : '')}>_ Munkatársaink</div>
                    <CgChevronDown className={'text-primary text-4xl md:text-7xl font-extrabold transition-colors ' + (props.dark ? '' : 'group-hover:text-white')} />
                </Link>
            }
        />
    );
}