import { useEffect } from 'react';
import NBossCMSClient from 'cms/NBossCMS.js';

import nBossConnection from 'services/nBossConnection.js';

function App() {
  useEffect(() => {
    nBossConnection.setErrorHandler((error) => {
      alert(error);
    });
  });
  return (
    <NBossCMSClient domainName='law.bme.hu' />
  );
}

export default App;