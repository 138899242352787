
export default function PrefixLabel(props) {
    return (
        <div className={'flex items-center justify-end font-bold uppercase ' + (props.className || '')}>

            <div className={'p-1 ' + (props.fontClassName || '')}>_&nbsp;</div>
            <div className={'leading-none p-1 ' + (props.fontClassName || '') + ' ' + (props.bgClassName || '') + ' ' + (props.labelClassName || '')}>{props.children}</div>

        </div>
    );
}