import { useState } from 'react';

import BlockRow from 'elements/block/BlockRow';
import FloatingTitleBlock from 'elements/block/FloatingTitleBlock';
import IntroductionBlock from 'elements/block/generic-blocks/IntroductionBlock';
import ColleaguesButtonBlock from 'elements/block/generic-blocks/ColleaguesButtonBlock';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';

export default function IntroductionPage(props) {
    const [page, setPage] = useState(null);

    const _data = {
        history: getValueFromParameterMap(page, 'history'),
    }

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full h-full flex-col'>

            {/* <BlockRow className='bg-primary shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]'>
                <AddressBlock />
            </BlockRow> */}

            <BlockRow className='bg-secondary'>
                <IntroductionBlock />
            </BlockRow>

            <BlockRow className='bg-secondary pt-0'>
                <FloatingTitleBlock
                    titleChildren={<>_ A tanszék története</>}
                    mainChildren={
                        <div className='flex flex-col text-lg'>
                            {_data.history && <div className='paragraph' dangerouslySetInnerHTML={{ __html: _data.history }} />}
                        </div>
                    }
                />
            </BlockRow>

            <BlockRow className='bg-secondary pt-0'>
                <ColleaguesButtonBlock />
            </BlockRow>

        </NBossCMSPage>
    );
}