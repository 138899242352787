import FloatingTitleBlock from 'elements/block/FloatingTitleBlock';

import { getValueFromParameterMap } from 'cms/NBossCMS';
import { getWebsite } from 'utils/WebsiteUtils';

export default function IntroductionBlock(props) {
    const _data = {
        introduction: getValueFromParameterMap(getWebsite().globalContent, 'introduction') && getValueFromParameterMap(getValueFromParameterMap(getWebsite().globalContent, 'introduction'), 'introduction'),
        author: getValueFromParameterMap(getWebsite().globalContent, 'introduction') && getValueFromParameterMap(getValueFromParameterMap(getWebsite().globalContent, 'introduction'), 'author'),
    }

    return (
        <FloatingTitleBlock
            titleChildren={<>_ Bemutatkozás</>}
            mainChildren={
                <div className='flex flex-col text-lg'>
                    {_data.introduction && <div className='paragraph' dangerouslySetInnerHTML={{ __html: _data.introduction }} />}
                    {_data.author && <div className='text-right font-bold mt-[1em]'>{_data.author}</div>}
                </div>
            }
        />
    );
}