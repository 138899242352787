import { useState } from 'react';

import BlockRow from 'elements/block/BlockRow';
import FloatingTitleBlock from 'elements/block/FloatingTitleBlock';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import { ConditionalWrapper } from 'utils/GenericUtils';

export default function ResearchPage(props) {
    const [page, setPage] = useState(null);

    const _data = {
        title: getValueFromParameterMap(page, 'title'),
        content: getValueFromParameterMap(page, 'content'),
        subTitle: getValueFromParameterMap(page, 'sub-title'),
        subContent: getValueFromParameterMap(page, 'sub-content'),
        publicationList: getValueFromParameterMap(page, 'publication-list') && getValueFromParameterMap(page, 'publication-list').contentList,
        awardsList: getValueFromParameterMap(page, 'awards-list') && getValueFromParameterMap(page, 'awards-list').contentList,
        researchList: getValueFromParameterMap(page, 'research-list') && getValueFromParameterMap(page, 'research-list').contentList,
    }

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full h-full flex-col'>

            <BlockRow className='bg-secondary'>
                <FloatingTitleBlock
                    titleChildren={<>_ {_data.title}</>}
                    subTitleChildren={<SubTitle number='1' title={_data.subTitle} content={_data.subContent} />}
                    mainChildren={_data.content && <div className='paragraph list-disc list-inside text-lg' dangerouslySetInnerHTML={{ __html: _data.content }} />}
                />
            </BlockRow>

            {/* <BlockRow className='bg-secondary'>
                <FloatingTitleBlock
                    subTitleChildren={<SubTitle number='2' title='A legkiemelkedőbb publikációink' />}
                    mainDisablePadding
                    mainClassName='bg-transparent'
                    mainChildren={_data.publicationList && <div className='flex flex-col gap-4'>
                        {_data.publicationList.map((item) => (getValueFromParameterMap(item, 'highlight') && <Publication key={item.key} data={item} />))}
                    </div>}
                />
            </BlockRow> */}

            <BlockRow className='bg-secondary'>
                <FloatingTitleBlock
                    subTitleChildren={<SubTitle number='2' title='Projektek és innovációs tevékenység' />}
                    mainDisablePadding
                    mainClassName='bg-transparent'
                    mainChildren={_data.researchList && <div className='flex flex-col gap-4'>
                        <div className='bg-white px-6 md:px-8 py-6 block-content uppercase font-bold'>A tanszéken jelenleg zajló kutatási programok:</div>
                        <div className='flex flex-col gap-4'>
                            {_data.researchList.map((item) => (<Research key={item.key} data={item} />))}
                        </div>
                    </div>}
                />
            </BlockRow>

            <BlockRow className='bg-secondary'>
                <FloatingTitleBlock
                    subTitleChildren={<SubTitle number='3' title='Eredmények és díjak' />}
                    mainDisablePadding
                    mainClassName='bg-transparent'
                    mainChildren={_data.awardsList && <div className='flex flex-col gap-4'>
                        {_data.awardsList.map((item) => (<Award key={item.key} data={item} />))}
                    </div>}
                />
            </BlockRow>

            <BlockRow className='bg-secondary'>
                <FloatingTitleBlock
                    titleChildren={<>_ Publikációk</>}
                    mainChildren={_data.publicationList && <PubicationLister list={_data.publicationList} />}
                />
            </BlockRow>

        </NBossCMSPage>
    );
}

function SubTitle(props) {
    return (
        <div className='flex flex-col gap-6'>
            <div className='flex items-end md:items-start md:flex-col gap-6'>
                {props.number && <div className='text-7xl font-extrabold text-white align-baseline'>{props.number}</div>}
                {props.title && <div className='font-bold'>{props.title}</div>}
            </div>
            {props.content && <div>{props.content}</div>}
        </div>
    );
}

// function Publication(props) {
//     const _data = {
//         author: getValueFromParameterMap(props.data, 'author') && getValueFromParameterMap(getValueFromParameterMap(props.data, 'author'), 'teljes-nev'),
//         title: getValueFromParameterMap(props.data, 'title'),
//         date: getValueFromParameterMap(props.data, 'date') && new Date(getValueFromParameterMap(props.data, 'date')).toLocaleDateString(),
//         url: getValueFromParameterMap(props.data, 'file') && getValueFromParameterMap(props.data, 'file').downloadUrl
//     }

//     return (<ListItem title={_data.author} content={_data.title} date={_data.date} url={_data.url} />);
// }

function Award(props) {
    const _data = {
        colleague: getValueFromParameterMap(props.data, 'colleague') && getValueFromParameterMap(getValueFromParameterMap(props.data, 'colleague'), 'teljes-nev'),
        description: getValueFromParameterMap(props.data, 'description'),
        date: getValueFromParameterMap(props.data, 'date') && new Date(getValueFromParameterMap(props.data, 'date')).toLocaleDateString(),
        url: getValueFromParameterMap(props.data, 'url')
    }

    return (<ListItem title={_data.colleague} content={_data.description} date={_data.date} url={_data.url} />);
}

function Research(props) {
    const _data = {
        title: getValueFromParameterMap(props.data, 'title'),
        content: getValueFromParameterMap(props.data, 'content'),
    }

    return (<ListItem title={_data.title} content={_data.content} />);
}

function ListItem(props) {
    return (
        <ConditionalWrapper condition={props.url} wrapper={children => <a className='group' href={props.url}>{children}</a>}>
            <div className={'w-full bg-white group-hover:bg-black transition-colors py-6 px-6 md:px-8 block-content'}>
                {props.title && <div className='font-bold group-hover:text-primary transition-colors mb-2'>{props.title}</div>}
                {props.content && <div className='group-hover:text-white transition-colors' dangerouslySetInnerHTML={{ __html: props.content }} />}
                {props.date && <div className='text-sm mt-2 group-hover:text-primary transition-colors'>{props.date}</div>}
            </div>
        </ConditionalWrapper>
    );
}

function PubicationLister(props) {
    const headerClassName = 'uppercase font-bold';

    const getAuthor = (item) => {
        if (getValueFromParameterMap(item, 'author')) return getValueFromParameterMap(getValueFromParameterMap(item, 'author'), 'teljes-nev')
        return null
    }

    const getUrl = (item) => {
        if (getValueFromParameterMap(item, 'file')) return getValueFromParameterMap(item, 'file').downloadUrl
        return null
    }

    return (<div className='flex flex-col'>
        <PublicationLine
            className='pb-8 hidden sm:flex md:hidden lg:flex'
            col1Content={<div className={headerClassName}>Cím</div>}
            col2Content={<div className={headerClassName}>Kutatók</div>}
            col3Content={<div></div>} />

        <div className='flex flex-col'>
            {props.list.map((item) => (<PublicationLine
                key={item.key}
                className='py-2 last:pb-0 first:pt-0'
                col1Content={<div className='text-base'>{getValueFromParameterMap(item, 'title')}</div>}
                col2Content={<div className='text-base'>{getAuthor(item)}</div>}
                col3Content={<a href={getUrl(item)} className={'text-sm font-bold uppercase ' + (getUrl(item) ? 'text-primary hover:text-black hover:underline decoration-2' : 'text-neutral-400')}>Letöltés</a>} />))}
        </div>

    </div>);
}

function PublicationLine(props) {
    return (
        <div className={'w-full block-content flex flex-col sm:flex-row md:flex-col lg:flex-row gap-2 sm:gap-4 md:gap-2 lg:gap-4 ' + (props.className || '')}>
            {props.col1Content && <div className='flex-1 flex items-start order-2 sm:order-1 md:order-2 lg:order-1'>{props.col1Content}</div>}
            {props.col2Content && <div className='w-full sm:w-[30%] md:w-full lg:w-[30%] flex items-start order-1 sm:order-2 md:order-1 lg:order-2 font-bold'>{props.col2Content}</div>}
            {props.col3Content && <div className='w-full sm:w-[10%] md:w-full lg:w-[10%] flex items-start order-3'>{props.col3Content}</div>}
        </div>
    );
}