import { CgChevronDown } from 'react-icons/cg';

import FloatingTitleBlock from 'elements/block/FloatingTitleBlock';

import { getValueFromParameterMap } from 'cms/NBossCMS';
import { getWebsite } from 'utils/WebsiteUtils';

export default function AddressBlock(props) {

    return (
        <FloatingTitleBlock
            titleClassName='lg:mt-16 flex-shrink whitespace-nowrap'
            titleChildren={
                <div className='flex gap-8 items-center justify-between'>

                    <div>_ Térkép</div>

                    <a href='https://goo.gl/maps/N27KSY1W91zw7f748' target='_blank' rel='noreferrer' className='flex items-center group'>
                        <div className='text-xl transition-colors group-hover:text-primary'>Google Maps</div>
                        <div className='flex h-0 items-center'>
                            <CgChevronDown className='text-primary text-5xl font-extrabold group-hover:text-white transition-colors' />
                        </div>
                    </a>

                </div>
            }
            mainDisablePadding
            mainClassName='bg-transparent lg:w-full lg:-ml-16 xl:-ml-48'
            mainChildren={
                <div className='relative flex flex-col'>

                    <div className='relative w-full aspect-[12/7] lg:aspect-[1028/525] overflow-hidden'>
                        <div className='absolute -bottom-[0px] -left-[300px] sm:-left-[220px] lg:bottom-0 lg:left-0 w-[920px] h-[470px] lg:w-[1180px] lg:h-[603px] bg-left-bottom bg-no-repeat' style={{ backgroundImage: 'url(/assets/images/map-vector.svg)' }} />
                    </div>

                    <Pointer className='pt-10 ml-[10px] sm:ml-[95px] -mt-[15px] lg:ml-[415px] lg:-mt-[20px]' />

                </div>
            }
        />
    );
}

function Pointer(props) {
    const _data = {
        addressFirstLine: getValueFromParameterMap(getWebsite().globalContent, 'contact-data') && getValueFromParameterMap(getValueFromParameterMap(getWebsite().globalContent, 'contact-data'), 'address-first-line'),
        addressSecondLine: getValueFromParameterMap(getWebsite().globalContent, 'contact-data') && getValueFromParameterMap(getValueFromParameterMap(getWebsite().globalContent, 'contact-data'), 'address-second-line'),
        addressThirdLine: getValueFromParameterMap(getWebsite().globalContent, 'contact-data') && getValueFromParameterMap(getValueFromParameterMap(getWebsite().globalContent, 'contact-data'), 'address-third-line'),
    }

    return (
        <div className={'relative flex ' + (props.className || '')}>

            <div className='absolute bottom-0 left-0'>

                <div className='w-1 h-[80px] bg-black rotate-45 origin-bottom-left flex justify-end'>
                    <div className='relative w-4 h-4 -rotate-45 flex-shrink-0'>
                        <div className='absolute w-4 h-4 bottom-0 -right-2 bg-black'>
                            <div className='w-full h-full bg-black animate-ping' />
                        </div>
                    </div>
                </div>
                <div className='w-1 h-[155px] lg:h-[185px] bg-black'></div>
            </div>

            <div className='h-[70px] flex flex-col items-start justify-between ml-4 flex-grow-0'>
                {_data.addressFirstLine && <div className='font-bold whitespace-nowrap'>{_data.addressFirstLine}</div>}
                {_data.addressSecondLine && <div className='text-sm md:text-base whitespace-nowrap'>{_data.addressSecondLine}</div>}
                {_data.addressThirdLine && <div className='text-sm md:text-base whitespace-nowrap'>{_data.addressThirdLine}</div>}
            </div>

        </div>
    );
}