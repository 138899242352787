import { useState } from 'react';
import { CgChevronDown, CgTime } from 'react-icons/cg';

import FloatingTitleBlock from 'elements/block/FloatingTitleBlock';

import { getValueFromParameterMap } from 'cms/NBossCMS';

export default function EventBlock(props) {
    const [open, setOpen] = useState(false);

    const _data = {
        title: getValueFromParameterMap(props.data, 'title'),
        subTitle: getValueFromParameterMap(props.data, 'sub-title'),
    }

    return (
        <FloatingTitleBlock
            titleChildren={'_ ' + _data.title}
            titleAdditionChildren={_data.subTitle}
            mainDisablePadding
            mainChildren={<Content data={props.data} open={open} onOpenChaged={(o) => setOpen(o)} />}
        />
    );
}

function Content(props) {
    const _data = {
        title: getValueFromParameterMap(props.data, 'title'),
        date: getValueFromParameterMap(props.data, 'date'),
        content: getValueFromParameterMap(props.data, 'content'),
        contentShort: getValueFromParameterMap(props.data, 'content-short'),
        timeList: getValueFromParameterMap(props.data, 'time-list') && getValueFromParameterMap(props.data, 'time-list').contentList,
    }

    return (
        <div className='flex flex-col gap-4 block-content pt-6 floating-main-py'>

            {(_data.contentShort && !props.open) && <div className='floating-main-px' dangerouslySetInnerHTML={{ __html: _data.contentShort }} />}

            {props.open && <div className='flex flex-col gap-8'>
                {_data.content && <div className='floating-main-px' dangerouslySetInnerHTML={{ __html: _data.content }} />}
                {(_data.timeList && _data.timeList.length > 0) && <div className='flex flex-col gap-8'>{_data.timeList.map((item) => (<TimeItem key={item.key} data={item} />))}</div>}
            </div>}

            <div className='floating-main-px w-full flex items-center gap-4 justify-between'>

                <div className='flex flex-col lg:flex-row gap-2 md:gap-4 flex-1 md:flex-auto'>
                    <DateItem label='Időpont' date={_data.date} />
                    <DateItem label='Közzététel' date={_data.date} secondary />
                </div>

                <button className='group' onClick={() => props.onOpenChaged(!props.open)}>
                    <CgChevronDown className={'text-primary text-4xl md:text-7xl font-extrabold transition group-hover:text-black ' + (props.open ? 'rotate-180' : '')} />
                </button>

            </div>

        </div>
    );
}

function DateItem(props) {
    const commonClassName = 'font-bold px-2.5 py-1 flex-1 md:flex-auto text-xs md:text-base'
    return (
        <div className='flex items-center'>
            <div className={commonClassName + ' ' + (props.secondary ? 'bg-secondary' : 'bg-black text-white')}>_ {props.label}:</div>
            <div className={commonClassName + ' ' + (props.secondary ? 'bg-primary text-white' : 'bg-primary text-white')}>{new Date(props.date).toLocaleDateString()}</div>
        </div>
    );
}

function TimeItem(props) {
    const _data = {
        start: getValueFromParameterMap(props.data, 'time-start'),
        end: getValueFromParameterMap(props.data, 'time-end'),
        content: getValueFromParameterMap(props.data, 'content'),
    }

    const getTime = (date) => {
        const d = new Date(date);
        return d.getHours() + (d.getHours() < 10 ? '0' : '') + '.' + d.getMinutes() + (d.getMinutes() < 10 ? '0' : '');
    }

    return (
        <div className='relative w-full'>

            <div className='md:absolute left-0 top-0 md:w-0 mb-4 md:mb-0'>

                <div className='relative'>

                    <div className='md:absolute right-0 top-0'>
                        <div className='flex justify-between items-center gap-2 bg-black md:bg-primary-light px-4 py-2 text-white font-bold text-2xl shadow-lg'>
                            <div className='flex'>
                                <div>{getTime(_data.start)}</div>
                                <div>&nbsp;-&nbsp;</div>
                                {_data.end && <div>{getTime(_data.end)}</div>}
                            </div>
                            <CgTime className='md:hidden text-primary' />
                        </div>
                    </div>

                </div>
            </div>

            <div className='flex flex-col gap-2 floating-main-px'>
                <div className='w-6 h-1 bg-primary hidden md:block'></div>
                {_data.content && <div className='paragraph' dangerouslySetInnerHTML={{ __html: _data.content }} />}
            </div>

        </div>
    );
}