import FloatingTitleBlock from 'elements/block/FloatingTitleBlock';

export default function AddressBlock(props) {
    return (
        <FloatingTitleBlock
            titleChildren={props.title}
            titleAdditionChildren={props.subTitle}
            mainChildren={<input className='w-full search-input' value={props.value} onChange={(e) => props.onChange(e.target.value)} placeholder={props.placeholder} />}
        />
    );
}