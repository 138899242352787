
import { useState } from 'react';

import BlockRow from 'elements/block/BlockRow';
import AddressBlock from 'elements/block/generic-blocks/AddressBlock';
import MapBlock from 'elements/block/generic-blocks/MapBlock';
import IntroductionBlock from 'elements/block/generic-blocks/IntroductionBlock';
// import ColleaguesButtonBlock from 'elements/block/generic-blocks/ColleaguesButtonBlock';
import EventBlock from 'elements/block/generic-blocks/EventBlock';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';

export default function MainPage(props) {
    const [page, setPage] = useState(null);

    const _data = {
        eventList: getValueFromParameterMap(page, 'event-list') && getValueFromParameterMap(page, 'event-list').contentList,
    }

    const hasHighlightEvent = (data) => {
        for (let key in data)
            if (getValueFromParameterMap(data[key], 'highlight'))
                return true;
        return false;
    }

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full h-full flex-col'>


            <BlockRow className='pt-0'>
                <IntroductionBlock />
            </BlockRow>

            {/* <BlockRow className='pt-0'>
                <ColleaguesButtonBlock dark />
            </BlockRow> */}

            {_data.eventList && hasHighlightEvent(_data.eventList) && _data.eventList.map((item, index) => (
                getValueFromParameterMap(item, 'highlight') && <BlockRow className='pt-0' key={item.key}>
                    <EventBlock data={item} />
                </BlockRow>
            ))}

            <BlockRow className='pt-0'>
                <AddressBlock />
            </BlockRow>

            <BlockRow className='pt-0'>
                <MapBlock />
            </BlockRow>

        </NBossCMSPage>
    );
}