
export default function BlockRow(props) {
    return (
        <div className={'w-full py-8 site-main-px flex justify-center ' + (props.className || '')}>
            <div className='w-full max-w-boxed flex items-center justify-end'>
                <div className={'w-full flex justify-end ' + (props.fullWidth ? '' : '2xl:w-[80%]') + (props.contentClassName || '')}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}