import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { NavLink, useLocation } from 'react-router-dom';

import PrefixLabel from 'elements/PrefixLabel';

import { getWebsite } from 'utils/WebsiteUtils';
import { getValueFromParameterMap } from 'cms/NBossCMS';

import CmsFrontendControl from 'services/CmsFrontendControl';

export default function MenuSlider(props) {
    let location = useLocation();

    const _data = {
        title: getValueFromParameterMap(getWebsite().globalContent, 'title'),
        subTitle: getValueFromParameterMap(getWebsite().globalContent, 'sub-title'),
        mainMenu: getValueFromParameterMap(getWebsite().globalContent, 'main-menu'),
        highlightMenu: getValueFromParameterMap(getWebsite().headerContent, 'highlight-menu'),
        copyright: getValueFromParameterMap(getWebsite().footerContent, 'copyright'),
    }

    useEffect(() => {
        if (props.onSliderClose())
            props.onSliderClose()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <motion.div
            className={'fixed w-full h-full flex flex-col ' + (props.className || '')}
            initial={props.open ? 'visible' : 'hidden'}
            animate={props.open ? 'visible' : 'hidden'}
            variants={{ visible: { opacity: 1, display: 'flex' }, hidden: { opacity: 0, transitionEnd: { display: 'none' } } }}>

            <motion.div
                className='absolute w-full h-full bg-black bg-opacity-30'
                initial={props.open ? 'visible' : 'hidden'}
                animate={props.open ? 'visible' : 'hidden'}
                variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}
                onClick={() => props.onSliderClose()} />

            <motion.div
                className='w-full max-h-full bg-black shadow-xl flex flex-col overflow-hidden pt-2 pb-6 px-6'
                initial={props.open ? 'visible' : 'hidden'}
                animate={props.open ? 'visible' : 'hidden'}
                transition={{ ease: 'easeInOut' }}
                variants={{ visible: { y: 0, display: 'flex' }, hidden: { y: '-100%', transitionEnd: { display: 'none' } } }}>

                <div className='h-16'></div>

                <div className='flex flex-col'>

                    <div className='uppercase text-xs font-light leading-5 text-white pb-3'>
                        <div>{_data.title}</div>
                        <div>{_data.subTitle}</div>
                    </div>

                    <PrefixLabel fontClassName='text-4xl text-white' bgClassName='bg-white' labelClassName='text-black' className='-mb-6 mr-6 z-10'>Menü</PrefixLabel>

                    <MenuList className='' menu={_data.mainMenu} />

                    {/* <LanguageSelector className='mt-4' /> */}

                </div>

            </motion.div>

        </motion.div >
    );
}

function MenuList(props) {
    return (
        <div className={'flex flex-col p-4 overflow-hidden bg-primary shadow-inner ' + (props.className || '')}>


            <div className='flex flex-col items-start'>
                {props.menu && props.menu.itemList.map((item, index) => (<MenuItem key={item.key} data={item} highlight={props.highlight} first={index === 0} last={index === props.menu.itemList.length - 1} />))}
            </div>

        </div>
    );
}

function MenuItem(props) {
    const activeBaseClassName = 'font-bold uppercase border-b-2 border-white py-2';
    const normalClassName = 'text-black';
    const activeClassName = 'text-white';

    return (
        <NavLink to={props.data.fullPath} className={({ isActive }) => isActive ? (activeBaseClassName + ' ' + activeClassName) : (activeBaseClassName + ' ' + normalClassName)}>{props.data.title}</NavLink>
    );
}

function LanguageSelector(props) {
    const _data = {
        availableLocales: getWebsite().availableLocales,
        currentLocale: getWebsite().currentLocale,
    };

    return (
        <div className={'w-full flex items-center gap-4 px-4 py-3 bg-secondary ' + (props.className || '')}>
            <div className='font-bold uppercase'>_ Nyelv</div>
            <div className='flex-1 flex'>
                {_data.availableLocales && _data.availableLocales.map((item) => (<LanguageItem key={item.key} data={item} active={item.key === _data.currentLocale.key} />))}
            </div>
        </div>
    );
}

function LanguageItem(props) {
    const onLangChange = (key) => {
        CmsFrontendControl.setLocale(key).then(result => { result && window.location.reload() });
    };

    return (
        <button className={'flex-1 uppercase py-1 text-sm font-medium tracking-wider ' + (props.active ? 'bg-black text-white' : 'bg-white')} onClick={() => onLangChange(props.data.key)}>
            <div>{props.data.title}</div>
        </button>
    );
}