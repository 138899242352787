import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { motion } from 'framer-motion';

import Header from 'components/Header';
import Footer from 'components/Footer';

// import CookieConsent from 'elements/CookieConsent';
// import Logo from 'elements/Logo';

import MainPage from 'pages/MainPage';
import ContactPage from 'pages/ContactPage';
import IntroductionPage from 'pages/IntroductionPage';
import ResearchPage from 'pages/ResearchPage';
import ColleaguesPage from 'pages/ColleaguesPage';
import EventPage from 'pages/EventPage';
import SubjectsPage from 'pages/SubjectsPage';
import TimetablePage from 'pages/TimetablePage';
import ContentPage from 'pages/ContentPage';
import Error404Page from 'pages/Error404Page';

import Background from 'elements/Background';
import Heading from 'elements/Heading';

import CmsFrontendControl from 'services/CmsFrontendControl';
import { setWebsite } from 'utils/WebsiteUtils';
import ScrollToTop from 'utils/ScrollToTop';

const pageDictionary = {
    'main-page': { page: <MainPage />, templateProps: {} },
    'contact-page': { page: <ContactPage />, templateProps: {} },
    'introduction-page': { page: <IntroductionPage />, templateProps: {} },
    'research-page': { page: <ResearchPage />, templateProps: {} },
    'colleagues-page': { page: <ColleaguesPage />, templateProps: {} },
    'event-page': { page: <EventPage />, templateProps: {} },
    'subjects-page': { page: <SubjectsPage />, templateProps: {} },
    'timetable-page': { page: <TimetablePage />, templateProps: {} },
    'content': { page: <ContentPage />, templateProps: {} },
    '404': { page: <Error404Page />, templateProps: {} },
};

const pageKey404 = 'egyszeru-oldal';

const createRoutes = (data) => {
    let _routes = [];
    if (data.mainPage)
        _routes.push({ path: '/', element: React.cloneElement(pageDictionary[data.mainPage.pageTemplate].page, { pageKey: data.mainPage.pageKey, templateProps: pageDictionary[data.mainPage.pageTemplate].templateProps }) });
    for (let key in data.sitemap)
        if (data.sitemap[key].pageTemplate && pageDictionary[data.sitemap[key].pageTemplate])
            _routes.push({ path: key, element: React.cloneElement(pageDictionary[data.sitemap[key].pageTemplate].page, { pageKey: data.sitemap[key].pageKey, templateProps: pageDictionary[data.sitemap[key].pageTemplate].templateProps }) });
        else
            console.log('[Missing template] ' + (data.sitemap[key].pageTemplate ? data.sitemap[key].pageTemplate : 'No template defined.') + ' [for] ' + key);
    _routes.push({ path: '*', element: React.cloneElement(pageDictionary['404'].page, { pageKey: pageKey404 }) });
    return _routes;
}

export default function NBossCMSClient(props) {
    const [website, _setWebsite] = useState(null);
    const [routes, setRoutes] = useState(null);

    useEffect(() => {
        if (!website && props.domainName)
            CmsFrontendControl.getWebsite(props.domainName, window.location.pathname).then(result => _setWebsite(result));
    }, [website, props.domainName]);

    useEffect(() => {
        if (website !== null) {
            setWebsite(website);
            setRoutes(createRoutes(website))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [website]);

    if (website && routes) {
        return (
            <Router>

                <div className='relative bg-primary min-h-screen w-full flex flex-col z-10'>

                    <Header className='z-50' />

                    <Background className='pt-20 lg:pt-40' />

                    <Heading className='pt-8 md:pt-12 lg:pt-40' compact={window.location.pathname !== '/'} />

                    <div className='relative w-full min-h-full flex-1 flex flex-col items-center '>

                        <Routes>{routes.map((route, index) => (<Route key={route.path} exact path={route.path} element={route.element} />))}</Routes>

                        <ScrollToTop />

                    </div>

                </div>

                <Footer className='w-full sticky top-0 bottom-0 left-0 right-0' />

            </Router >
        );
    } else {
        return (
            <div className='w-full min-h-screen flex flex-col justify-center items-center gap-4'>

                {/* <Logo /> */}

            </div>
        );
    }
}

export function NBossCMSPage(props) {
    useEffect(() => {
        CmsFrontendControl.getPage(props.pageprops.pageKey).then(result => { props.pageDataReady ? props.pageDataReady(result) : console.log('No pageDataReady callback defined.') });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.pageprops.pageKey]);

    return (<motion.div
        className={props.className}
        transition={{ duration: .3 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}>
        {props.children}
    </motion.div>);
}

export const getValueFromParameterMap = (data, key) => {
    if (data && key && data.parameterMap[key])
        return data.parameterMap[key].value;
    return null;
};