import { useState } from 'react';

import BlockRow from 'elements/block/BlockRow';
import SearchBlock from 'elements/block/generic-blocks/SearchBlock';
import FloatingTitleBlock from 'elements/block/FloatingTitleBlock';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';

export default function SubjectsPage(props) {
    const [page, setPage] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const _data = {
        title: getValueFromParameterMap(page, 'title'),
        content: getValueFromParameterMap(page, 'content'),
        image: getValueFromParameterMap(page, 'image') && getValueFromParameterMap(page, 'image').downloadUrl,
        subjectList: getValueFromParameterMap(page, 'subject') && getValueFromParameterMap(page, 'subject').contentList,
    }

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full h-full flex-col'>

            <BlockRow className='pt-0'>
                <FloatingTitleBlock
                    titleChildren={<>_ {_data.title}</>}
                    image={_data.image}
                    mainChildren={_data.content && <div className='block-content paragraph' dangerouslySetInnerHTML={{ __html: _data.content }} />}
                />
            </BlockRow>

            {_data.subjectList && <BlockRow className='pt-0'>
                <SearchBlock title='Tantárgyak' placeholder='Tantárgyak keresése' value={searchTerm} onChange={v => setSearchTerm(v)} />
            </BlockRow>}

            {_data.subjectList && <BlockRow className='pt-0'>
                <SubjectList data={_data.subjectList} />
            </BlockRow>}

        </NBossCMSPage>
    );
}

function SubjectList(props) {
    return (
        <div className='w-full flex flex-col gap-2'>

            <Line className='hidden md:grid' header />
            {props.data.map((item, index) => (<Line key={index} data={item} />))}

        </div>
    );
}

function Line(props) {
    return (
        <Row
            className={props.className}
            header={props.header}
            subjectLabel='Tárgynév'
            subject={getValueFromParameterMap(props.data, 'targynev')}
            codeLabel='Tárgynév'
            code={getValueFromParameterMap(props.data, 'targykod')}
            tutorLabel='Oktató'
            tutor={props.header ? null : (getValueFromParameterMap(props.data, 'tutor') ? <TutorList data={getValueFromParameterMap(props.data, 'tutor')} /> : '_ ? ')}
        />
    );
}

function Row(props) {
    return (
        <div className={'p-4 grid grid-cols-2 md:grid-cols-5 gap-4 sm:gap-2 md:gap-4 ' + (props.header ? 'bg-primary-light' : 'bg-white') + ' ' + (props.className || '')}>

            <Cell className='md:col-span-2' label={props.subjectLabel} value={props.subject} />
            <Cell label={props.codeLabel} value={props.code} />
            <Cell className='col-span-2' label={props.tutorLabel} value={props.tutor} />

        </div>
    );
}

function Cell(props) {
    const labelClassName = 'text-sm md:text-xs lg:text-sm xl:text-base';

    return (
        <div className={'' + (props.className || '')}>

            {props.label && <div className={'uppercase font-bold ' + labelClassName + ' ' + (props.labelClassName || '') + ' ' + (props.value ? 'md:hidden bg-black text-white px-2 py-1 mb-1' : '')}>{(props.value ? '_ ' : '') + props.label}</div>}
            {props.value && <div className={' ' + labelClassName + ' ' + (props.valueClassName || '')}>{props.value}</div>}

        </div>
    );
}

function TutorList(props) {
    return (
        <div className='w-full flex flex-wrap'>

            {props.data.map((item, index) => (<div key={index} className='flex'>{index ? <span className=' text-primary'>&nbsp;&nbsp;/&nbsp;&nbsp;</span> : null}<div key={item.id}>{getValueFromParameterMap(item, 'teljes-nev')}</div></div>))}

        </div>
    );
}