import FloatingTitleBlock from 'elements/block/FloatingTitleBlock';

import { getValueFromParameterMap } from 'cms/NBossCMS';
import { getWebsite } from 'utils/WebsiteUtils';

export default function AddressBlock(props) {
    const _data = {
        addressFirstLine: getValueFromParameterMap(getWebsite().globalContent, 'contact-data') && getValueFromParameterMap(getValueFromParameterMap(getWebsite().globalContent, 'contact-data'), 'address-first-line'),
        addressSecondLine: getValueFromParameterMap(getWebsite().globalContent, 'contact-data') && getValueFromParameterMap(getValueFromParameterMap(getWebsite().globalContent, 'contact-data'), 'address-second-line'),
        addressThirdLine: getValueFromParameterMap(getWebsite().globalContent, 'contact-data') && getValueFromParameterMap(getValueFromParameterMap(getWebsite().globalContent, 'contact-data'), 'address-third-line'),
    }

    return (
        <FloatingTitleBlock
            titleChildren={<>_ A tanszék elérhetőségei</>}
            mainChildren={
                <div className='flex flex-col block-content'>
                    {_data.addressFirstLine && <div className='font-bold'>{_data.addressFirstLine}</div>}
                    {_data.addressSecondLine && <div className=''>{_data.addressSecondLine}</div>}
                    {_data.addressThirdLine && <div className=''>{_data.addressThirdLine}</div>}
                </div>
            }
        />
    );
}